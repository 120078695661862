const env = process.env.VUE_APP_ENV;

let envDomain, envUrl, wsUrl;

console.log("ENV: ", env);

if (env === 'production') {
  envDomain = window.location.origin.split("://")[1] + "/backend"
  if (envDomain === 'localhost/backend') {
    envUrl = `http://${envDomain}`
    wsUrl = `ws://${envDomain}`
  }
  else {
    envUrl = `https://${envDomain}`
    wsUrl = `wss://${envDomain}`
  }
} else if (env === 'staging') {
  envDomain = process.env.VUE_APP_DOMAIN_STAG
  envUrl = `https://${envDomain}`
  wsUrl = `wss://${envDomain}`
} else {
  const apiPort = process.env.VUE_APP_BACKEND_PORT;
  envDomain = `${process.env.VUE_APP_DOMAIN_DEV}:${apiPort}`
  envUrl = `http://${envDomain}`
  wsUrl = `ws://${envDomain}`
}

console.log(envUrl)
export const apiDomain = envDomain;
export const apiUrl = envUrl;
export const socketUrl = wsUrl;
export const appName = process.env.VUE_APP_NAME;
export const startPlanId = process.env.VUE_APP_DEFAULT_PLAN_ID
