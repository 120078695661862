<template>
    <q-drawer
        :model-value="leftDrawerOpen"
        show-if-above

        :mini="true"
        mini-to-overlay

        :width="200"
        :breakpoint="500"
        bordered
        :class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-3'"
    >
        <q-list class="flex-container">
          <q-item
              clickable
              v-ripple

              v-for="item in stdNavItems"
              :key="item.label"
              :to="item.to"
              :active-class="'bg-grey-4'"
          >
            <q-item-section avatar class="q-pa-sm flex justify-center items-center">
              <q-icon :name="item.icon"/>
              <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                {{ item.label }}
              </q-tooltip>
            </q-item-section>
          </q-item>

          <q-separator v-if="getIsLoggedIn" />
          <q-item
              clickable
              v-ripple

              v-show="getIsLoggedIn"
              v-for="item in adminNavItems"
              :key="item.label"
              :to="item.to"
          >
            <q-item-section avatar class="q-pa-sm flex justify-center items-center">
              <q-icon :name="item.icon" />
              <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                {{ item.label }}
              </q-tooltip>
            </q-item-section>
          </q-item>

          <q-item class="col-grow">
          </q-item>

          <div class="spacer"/>
          <q-separator />

          <q-item
              clickable
              v-ripple
              @click="showRestartDiag = true"
          >
            <q-item-section avatar class="q-pa-sm flex justify-center items-center">
              <q-icon name="restart_alt" />
              <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                Restart
              </q-tooltip>
            </q-item-section>
          </q-item>

          <q-item
              clickable
              v-ripple
              @click="showRebootDiag = true"
          >
            <q-item-section avatar class="q-pa-sm flex justify-center items-center">
              <q-icon name="power_settings_new" />
              <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                Reboot
              </q-tooltip>
            </q-item-section>
          </q-item>

          <q-separator />

          <q-item
              v-if="getIsLoggedIn"
              clickable
              v-ripple
              to="/logout"
          >
            <q-item-section avatar class="q-pa-sm flex justify-center items-center">
              <q-icon name="logout" />
              <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
                Logout
              </q-tooltip>
            </q-item-section>
          </q-item>
        </q-list>
    </q-drawer>
    <q-dialog v-model="showRestartDiag" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="restart_alt" color="primary" text-color="white" />
          <span class="q-ml-sm text-h6">
            Are you sure you want to restart?
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat color="primary" label="Restart"  @click="sendRestartRequest" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showRebootDiag" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="power_settings_new" color="primary" text-color="white" />
          <span class="q-ml-sm text-h6">
            Are you sure you want to reboot?
          </span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat color="primary" label="Reboot"  @click="sendRebootRequest" />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<style>

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.spacer {
  flex-grow: 1;
}
</style>

<script>
import { ref } from "vue";
import {storeToRefs} from "pinia/dist/pinia";
import { useQuasar } from "quasar";
import {useUserStore} from "@/store/user-store";

import { api } from '@/api';

export default {
    name: 'NavBar',

    props: {
        leftDrawerOpen: Boolean,
    },
    setup() {
        const  $q = useQuasar();
        const { getIsLoggedIn } = storeToRefs(useUserStore());

        const navItems = [
          { label: 'Testing', icon: 'science', to: '/' },
          { label: 'Past Runs', icon: 'list', to: '/runs' },
          // { label: 'Analytics', icon: 'analytics', to: '/analytics' },
          // { label: 'About', icon: 'info', to: '/about'},
          // { label: 'Profile', icon: 'person', to: '/profile' },
        ];

        const adminNavItems = [
          // { label: 'Debug', icon: 'bug_report', to: '/debug' },
          { label: 'Settings', icon: 'settings', to: '/settings' },
        ]

        const showRestartDiag = ref(false);

        const sendRestartRequest = async () => {
          try {
            console.log("Sending restart request")
            showRestartDiag.value = false;

            // Send the request to the server
            // Show a notification to the user
            $q.notify({
              progress: true,
              message: 'Restarting the system...',
              icon: 'restart_alt',
              color: 'white',
              textColor: 'primary',
              timeout: 15000
            })
            const result = await api.restart()
            console.log(result);
          } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error sending restart request:', error);

            // Notify the user of the failure
            $q.notify({
              color: 'negative',
              message: 'Failed to initiate restart.',
            });
          }
        };

        const showRebootDiag = ref(false);

        const sendRebootRequest = async () => {
          try {
            console.log("Sending reboot request")
            showRebootDiag.value = false;

            // Send the request to the server
            // Show a notification to the user
            $q.notify({
              progress: true,
              message: 'Rebooting the system...',
              icon: 'restart_alt',
              color: 'white',
              textColor: 'primary',
              timeout: 30000
            })
            const result = await api.reboot()
            console.log(result);
          } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error sending reboot request:', error);

            // Notify the user of the failure
            $q.notify({
              color: 'negative',
              message: 'Failed to initiate reboot.',
            });
          }
        };

        return {
          showRebootDiag,
          sendRebootRequest,
          sendRestartRequest,
          showRestartDiag,
          getIsLoggedIn,
          stdNavItems: navItems,
          adminNavItems: adminNavItems
        }
    }
}
</script>
  