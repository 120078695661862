<template>
  <q-page class="flex flex-center column background-image">
    <div class="image-overlay"></div>
    <div class="row">
      <div>
        <img class="foreground" alt="FixturFab logo" src="../assets/logo-white.svg" style="width: 200px; height: 200px">
      </div>
    </div>
    <div class="row">
      <div>
        <q-spinner-grid class="foreground" color="white" size="5em"/>
      </div>
    </div>
  </q-page>
</template>

<style>
.background-image {
  background-image: url('../assets/pick-n-place.jpg');
  background-size: cover;
  background-position: center;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1; /* Added z-index */
  
}

.foreground {
  position: relative; /* Added position relative */
  z-index: 2; /* Added z-index greater than overlay */
}

.row {
  display: flex;
}

.column {
  flex: 1;
  padding: 10px;
}
</style>

<script>
export default {
  name: 'SplashScreen'
}
</script>
