import './index.css'
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from './App.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

const pinia = createPinia()
createApp(App).use(Quasar, quasarUserOptions).use(pinia).use(router).mount("#app");