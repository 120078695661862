import { defineStore } from 'pinia';
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import { api } from "@/api";

export const useUserStore = defineStore('user', {
  state: () => ({
    isLoggedIn: null,
    token: '',
    loginError: false,
    userProfile: null,
    users: [],
  }),
  getters: {
    hasAdminAccess: (state) => {
      return state.userProfile && state.userProfile.is_active && state.userProfile.role_id === 1;
    },
    getIsLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    getUserProfile: (state) => {
      return state.userProfile;
    },
    getUsers: (state) => {
      return state.users;
    }
  },
  actions: {
    reset() {
      this.$reset();
    },
    async removeLogIn() {
      console.log("removeLogIn: removing token and logging out")
      removeLocalToken();
      this.isLoggedIn = false;
      this.token = '';
    },
    async logIn(username, password) {
      console.log('logIn: Logging user in')
      try {
        const response = await api.loginToken(username, password);
        const token = response.data.access_token;
        if (token) {
          saveLocalToken(token);
          this.token = token;
          const response = await api.getMe(this.token);
          this.userProfile = response.data;
          this.isLoggedIn = true;
          this.loginError = false;
          console.log('logIn: -> Successful');
        } else {
          await this.removeLogIn();
        }
      } catch (err) {
        console.error(err)
        this.loginError = true;
        await this.removeLogIn();
      }
    },
    async logOut() {
      console.log('logOut: Logging user out')
      await this.removeLogIn();
    },
    async checkLoggedIn() {
      console.log('checkLoggedIn: Checking if user is logged in.')
      if (!this.isLoggedIn) {
        console.log("-> not logged in, checking ")
        if (!this.token) {
          console.log('checkLoggedIn: -> No token found, reading local token')
          const localToken = getLocalToken();
          if (localToken) {
            console.log('checkLoggedIn: -> local token found')
            this.token = localToken;
          }
        }
        if (this.token) {
          console.log("validating login")
          try {
            const response = await api.getMe(this.token);
            this.isLoggedIn = true;
            this.userProfile = response.data;
          } catch (error) {
            console.log("removing token, err=", error)
            await this.removeLogIn();
          }
        } else {
          console.log('checkLoggedIn: No token found, user is logged out')
          console.log("removing token")
          await this.removeLogIn();
        }
      } else {
        console.log('checkLoggedIn: User is logged in')
      }
    },
    async fetchMe() {
      try {
        await this.checkLoggedIn();
        const response = await api.getMe(this.token);
        this.userProfile = response.data;
      } catch (error) {
        console.log(error)
      }
    },
    async updateMe(payload) {
      try {
        console.log("updateMe: updating user profile", payload);
        const response = await api.updateMe(this.token, payload);
        this.userProfile = response.data;
        return true;
      } catch (error) {
        console.log(error)
        return false;
      }
    },
    async fetchUsers() {
      try {
        await this.checkLoggedIn();
        const response = await api.getUsers(this.token);
        this.users = response.data;
      }
      catch (error) {
        console.log(error)
      }
    },
    async addUser(payload) {
      try {
        await this.checkLoggedIn();
        await api.createUser(this.token, payload);
        await this.fetchUsers();
      } catch (error) {
        console.log(error)
      }
    },
    async updateUser(userId, payload) {
      try {
        await this.checkLoggedIn();
        const response = await api.updateUser(this.token, userId, payload);
        console.log("updateUser: updating user profile, response:", response);
        await this.fetchUsers()
        return true;
      } catch (error) {
        console.log(error)
        return false;
      }
    },
    async deleteUser(userId) {
      try {
        await this.checkLoggedIn();
        await api.deleteUser(this.token, userId);
        this.users = this.users.filter(user => user.id !== userId);
        return true;
      } catch (error) {
        console.log(error)
        return false;
      }
    }
  }
});
