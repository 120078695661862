<template>
  <q-layout view="hHh lpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
        <q-toolbar-title>
          <q-avatar square>
            <img alt="FixturFab Logo" src="./assets/logo-white-no-text.svg">
          </q-avatar>
          FixturFab Test Runner
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <Navbar :leftDrawerOpen="leftDrawerOpen" />

    <q-page-container>
      <Splash v-if="getIsLoggedIn === null" />
      <router-view />
    </q-page-container>
  </q-layout>
  <barcode-scanner v-if="getIsLoggedIn === true"/>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import Splash from '@/components/Splash.vue';
import Navbar from "@/components/Navbar";
import { useUserStore } from '@/store/user-store';
import BarcodeScanner from "@/components/BarcodeScanner.vue";

const leftDrawerOpen = ref(true);
const { getIsLoggedIn } = storeToRefs(useUserStore());

const toggleLeftDrawer = () => {
  leftDrawerOpen.value = !leftDrawerOpen.value;
}
</script>
