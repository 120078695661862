export const getLocalToken = () => localStorage.getItem('token');

export const removeLocalToken = () => localStorage.removeItem('token');

export const saveLocalToken = (token) => localStorage.setItem('token', token);

export const getDefaultPlan = () => localStorage.getItem('plan');

export const removeDefaultPlan = () => localStorage.removeItem('plan');

export const saveDefautPlan = (planId) => localStorage.setItem('plan', planId);
