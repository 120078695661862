<template>
    <div autofocus ref="scanner"/>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from 'vue';
import {storeToRefs} from 'pinia';
import {useQuasar} from "quasar";

import { useRouter } from "vue-router";

import {useTestPlanStore} from '@/store/test-plan-store';

const testPlanStore = useTestPlanStore();
const {getCurrentTestPlan} = storeToRefs(testPlanStore);

const $q = useQuasar();
const router = useRouter();

const scanner = ref(null);

let scannedData = ref('');

const handleScannerInput = (event) => {
  if (event.key === 'Enter') {
    // Process the scanned data upon escape sequence (e.g., update state)
    scannedData.value = '';
  }
  if (event.key.length === 1) {
    scannedData.value += event.key;
    // console.log("handleScannerInput -> ", scannedData, scannedData.slice(-5))
  }
  if (scannedData.value.length >= 10 && scannedData.value.slice(-5) === '#CMD#') {
    // Process the scanned data upon escape sequence (e.g., update state)
    processCmd();
  }
};

const getDutIdx = (cmd) => {
  // Remove escape sequence
  return parseInt(cmd.split(',')[1]) - 1;
};

const processCmd = async () => {
  // Remove escape sequence
  let cmd = scannedData.value.substring(5, scannedData.value.length-5)
  console.log('processCmd -> ', cmd);

  if (cmd.includes('STARTID')) {
    let id = getDutIdx(cmd);
    console.log('processCmd -> STARTID:', id);
    const status = await testPlanStore.startTestSlotIdx(id);
    if (status === false) {
      $q.notify({
        color: 'negative',
        message: 'Failed to start test. Please reload page and wait for environment to load.',
        icon: 'error'
      });
    }
  } else if (cmd.includes('CANCELID')) {
    let id = getDutIdx(cmd);
    console.log('processCmd -> CANCELID:', id);
    await testPlanStore.cancelTestSlotIdx(id);
  } else if (cmd.includes('STARTALL')) {
    console.log('processCmd -> STARTALL');
    const status = await testPlanStore.startTestAllSlots();
    if (status === false) {
      $q.notify({
        color: 'negative',
        message: 'Failed to start test. Please reload page and wait for environment to load.',
        icon: 'error'
      });
    }
  } else if (cmd.includes('CANCELALL')) {
    console.log('processCmd -> CANCELALL');
    await testPlanStore.cancelTestAllSlots();
  } else if (cmd.includes("SELECT")) {
    const id = getDutIdx(cmd);
    // Check if there are dialog boxes waiting to be displayed for the selected slot
    let dialogs = await testPlanStore.getSlotDialogsAndNotifs(id);
    if (dialogs.length > 0) {
      console.log("processCmd -> SELECT, " + id + ": Dialogs waiting to be displayed");
      // Display the dialog box
      await testPlanStore.setSlotDialogFlag(id, true);
    } else {
      console.log("processCmd -> SELECT, " + id + ": No dialogs waiting to be displayed, go to RunTest view");
      // Navigate to the runner view for the selected slot
      await router.push({
        name: 'runtest',
        params: {
          id: id
        }
      });
    }
  } else if (cmd.includes('VIEW')){
    console.log('processCmd -> VIEW');
    let view = cmd.split(',')[1];
    if (view === 'RUNS') {
      console.log('processCmd -> VIEW RUNS');
      await router.push({name: 'runs'});
    } else if (view === 'SETTINGS') {
      console.log('processCmd -> VIEW SETTINGS');
      await router.push({name: 'settings'})
    } else if (view === 'TESTING') {
      console.log('processCmd -> VIEW TESTING');
      await router.push({name: 'testing'})
    } else {
      console.log('processCmd -> Unknown view: ', view);
    }
  } else {
    console.log('processCmd -> Unknown command: ', cmd);
  }
};

onMounted(async () => {
  // Focus on the input field when the component is mounted to capture scans
  document.addEventListener('keydown', handleScannerInput);
  if (scanner.value) {
    scanner.value.focus();
  }


  // Fetch currently selected test plan
  if (!getCurrentTestPlan.value) {
    console.log("BarcodeScanner.onMounted: Fetching current test plan")
    await testPlanStore.fetchCurrentTestPlan();
  }
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleScannerInput);
});
</script>
  